import React, { useState, Fragment, useEffect } from 'react';
import { Form, Container, Row, Col, Button, Navbar } from 'react-bootstrap';
import { Formik } from 'formik';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import cred from '../quickstart-1591255038542-f1a0c450f410.json';
//import Logo from '../assets/ljsLogo.png';
import Logo from '../assets/jars.png';
import navBarImg from '../assets/interface.png';
import { formHeaders, formSchema, formSchemaRows } from './FormMisc';

export const FormComponent = () => {
    const SPREADSHEET_ID = '1pW8vbOqXy0VtDbuwJx9m4lzpER6SNF_OCS3eM-HONsg';
    var service_id = 'default_service';
  var template_id = "jars";
    //const ACCESS_TOKEN = cred.access_token;

    const [formValues, setFormValues] = useState({});
    const [validationResult, setValidationResult] = useState(false);
    const [showToast, setShowToast] = useState({ value: false });
    const [rows, setRows] = useState([
        {
            key: 0,
            storeNumber: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            phone: ''
        }
    ]);
    const [savingResponse, setSavingResponse] = useState(false);

    // Function Handlers
    // Add a new site field
    const addSite = () => {
        let tempRows = rows;
        let mapRows = [];
        tempRows.forEach((row) => {
            mapRows.push(row);
        });
        mapRows.push({
            key: rows.length,
            storeNumber: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            phone: ''
        });
        setRows(mapRows);
    };

    // Remove the last site field
    const handleRemoveSite = (key) => {
        let tempRows = rows;
        let mapRows = [];
        tempRows.forEach((row) => {
            mapRows.push(row);
        });
        let filteredRows = mapRows.filter((row) => row.key !== key && row);
        setRows(filteredRows);
    };

    // Handle changes to main response values
    const handleMainFormChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    // Handle changes to site response values
    const handleRowChange = (e, key) => {
        let tempRows = rows;
        let mapRows = [];
        tempRows.forEach((row) => {
            mapRows.push(row);
        });
        mapRows.forEach((row) => {
            if (row.key === key) {
                row[e.target.name] = e.target.value;
            }
        });
        setRows(mapRows);
    };

    // Submit function
    const onSubmit = async () => {
        var template_params = {
            message_html: `You have a new form submission<br/>Business Legal Name : ${formValues.businessLegalName}<br/>Authorised Signer : ${formValues.authorizedSigner}`
        };

        if (!validationResult) {
            setSavingResponse(true);
            try {
                const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
                await doc.useServiceAccountAuth(cred);
                await doc.loadInfo();
                const sheet = doc.sheetsByIndex[0];
                const requestArray = [['-']];
                // Main Response Header
                let mainResponseHeader = formHeaders;
                requestArray.push(mainResponseHeader);
                // Main Response Values
                requestArray.push({
                    'Business Legal Name': formValues.businessLegalName,
                    'Authorized Signer - Name': formValues.ausName,
                    'Authorized Signer - Phone': formValues.ausPhone,
                    'Authorized Signer - Email': formValues.ausEmail,
                    'Secondary Contact Name': formValues.secName,
                    'Secondary Contact Email': formValues.secEmail,
                    'Billing Contact - Name': formValues.billName,
                    'Billing Contact - Phone': formValues.billPhone,
                    'Billing Contact - Email': formValues.billEmail,
                    'Billing Contact - Billing Address':
                        formValues.billingAddress,
                    City: formValues.city,
                    State: formValues.state,
                    Zip: formValues.zip,
                    'Payment Mode': formValues.paymentMode,
                    'Solution Option': formValues.solutionOption
                });
                // Site Response Header
                let siteRows = [
                    'Store#',
                    'Address',
                    'City',
                    'State',
                    'Zip'
                ];
                requestArray.push(siteRows);
                rows.forEach((row) => {
                    requestArray.push({
                        '#':
                            'Site' +
                            (row.storeNumber.length > 0
                                ? row.storeNumber
                                : row.key + 1),
                        'Business Legal Name': row.address,
                        'Authorized Signer - Name': row.city,
                        'Authorized Signer - Phone': row.state,
                        'Authorized Signer - Email': row.zip
                    });
                });

                await sheet.addRows(requestArray);
                window.emailjs.send(service_id, template_id, template_params);
                setSavingResponse(false);
                setShowToast({
                    value: true,
                    type: 'success',
                    message: 'Your response has been submitted successfully.'
                });
                setTimeout(() => window.location.reload(), 3000);
            } catch (error) {
                setSavingResponse(false);
                setShowToast({
                    value: true,
                    type: 'danger',
                    message: 'There has been an error. Please try again.'
                });
                setTimeout(() => setShowToast({ value: false }), 3000);
            }
        }

    };

    // Validation function
    const validateSchema = async () => {
        const formValueValidation = await formSchema.isValid(formValues);
        const siteValueValidation = await formSchemaRows.isValid(rows);
        setValidationResult(
            formValueValidation ? (siteValueValidation ? false : true) : false
        );
    };

    useEffect(() => {
        validateSchema();
        // eslint-disable-next-line
    }, [rows, formValues]);

    return (
        <div>
            <Navbar
                expand='lg'
                className='py-3'
                style={{
                  background: "#fff",
                  "borderBottom": "5px solid #2A324B",
                 }}
            >
                <Container>
                    <Navbar.Brand
                        href='https://jars.interfacesystems.com/'
                        target='_blank'
                    >
                        <img
                            alt=''
                            src={navBarImg}
                            height='45vh'
                            className='d-inline-block align-top'
                        />
                    </Navbar.Brand>
                    <Navbar.Brand
                        href='https://jarsbyfabioviviani.com/'
                        target='_blank'
                    >
                        <img
                            alt=''
                            src={Logo}
                            height='80vh'
                            className='d-inline-block align-top'
                        />
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <Container className='my-5'>
                <Formik
                    validationSchema={formSchema}
                    onSubmit={() => onSubmit()}
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{}}
                >
                    {({ handleSubmit, handleChange, touched, errors }) => (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId='row1'>
                                        <Form.Label className='font-weight-bold'>
                                            Business Legal Name
                                        </Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='businessLegalName'
                                            value={
                                                formValues.businessLegalName ||
                                                ''
                                            }
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleMainFormChange(e);
                                            }}
                                            isValid={
                                                touched.businessLegalName &&
                                                !errors.businessLegalName
                                            }
                                            isInvalid={
                                                !!errors.businessLegalName
                                            }
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            Required field
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Label className='font-weight-bold' style={{marginTop: '25px'}}>
                            Authorized Signer
                            </Form.Label>
                            <Row>
                                <Col md={6} xs={4}>
                                    <Form.Group controlId='row3'>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='ausName'
                                            value={formValues.ausName || ''}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleMainFormChange(e);
                                            }}
                                            isValid={
                                                touched.ausName && !errors.ausName
                                            }
                                            isInvalid={!!errors.ausName}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            Required field
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={3} xs={4}>
                                    <Form.Group controlId='row4'>
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='ausPhone'
                                            value={formValues.ausPhone || ''}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleMainFormChange(e);
                                            }}
                                            isValid={
                                                touched.ausPhone &&
                                                !errors.ausPhone
                                            }
                                            isInvalid={!!errors.ausPhone}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.ausPhone}
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={3} xs={4}>
                                    <Form.Group controlId='row5'>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type='email'
                                            name='ausEmail'
                                            value={formValues.ausEmail || ''}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleMainFormChange(e);
                                            }}
                                            isValid={
                                                touched.ausEmail &&
                                                !errors.ausEmail
                                            }
                                            isInvalid={!!errors.ausEmail}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.ausEmail}
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={4}>
                                    <Form.Group controlId='row3'>
                                        <Form.Label>Secondary Contact Name</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='secName'
                                            value={formValues.secName || ''}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleMainFormChange(e);
                                            }}
                                            isValid={
                                                touched.secName && !errors.secName
                                            }
                                            isInvalid={!!errors.secName}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            Required field
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6} xs={4}>
                                    <Form.Group controlId='row4'>
                                        <Form.Label>Secondary Contact Email</Form.Label>
                                        <Form.Control
                                            type='email'
                                            name='secEmail'
                                            value={formValues.secEmail || ''}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleMainFormChange(e);
                                            }}
                                            isValid={
                                                touched.secEmail &&
                                                !errors.secEmail
                                            }
                                            isInvalid={!!errors.secEmail}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.secEmail}
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Label className='font-weight-bold' style={{marginTop: '25px'}}>
                                Billing Contact
                            </Form.Label>
                            <Row>
                                <Col md={6} xs={4}>
                                    <Form.Group controlId='row3'>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='billName'
                                            value={formValues.billName || ''}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleMainFormChange(e);
                                            }}
                                            isValid={
                                                touched.billName && !errors.billName
                                            }
                                            isInvalid={!!errors.billName}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            Required field
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={3} xs={4}>
                                    <Form.Group controlId='row4'>
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='billPhone'
                                            value={formValues.billPhone || ''}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleMainFormChange(e);
                                            }}
                                            isValid={
                                                touched.billPhone &&
                                                !errors.billPhone
                                            }
                                            isInvalid={!!errors.billPhone}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.billPhone}
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={3} xs={4}>
                                    <Form.Group controlId='row5'>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type='email'
                                            name='billEmail'
                                            value={formValues.billEmail || ''}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleMainFormChange(e);
                                            }}
                                            isValid={
                                                touched.billEmail &&
                                                !errors.billEmail
                                            }
                                            isInvalid={!!errors.billEmail}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.billEmail}
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={12}>
                                    <Form.Group controlId='row6'>
                                        <Form.Label>Billing Address</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='billingAddress'
                                            value={
                                                formValues.billingAddress || ''
                                            }
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleMainFormChange(e);
                                            }}
                                            isValid={
                                                touched.billingAddress &&
                                                !errors.billingAddress
                                            }
                                            isInvalid={!!errors.billingAddress}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            Required field
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={2} xs={4}>
                                    <Form.Group controlId='row7'>
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='city'
                                            value={formValues.city || ''}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleMainFormChange(e);
                                            }}
                                            isValid={
                                                touched.city && !errors.city
                                            }
                                            isInvalid={!!errors.city}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            Required field
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={2} xs={4}>
                                    <Form.Group controlId='row8'>
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                            as='select'
                                            name='state'
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleMainFormChange(e);
                                            }}
                                            isValid={
                                                touched.state && !errors.state
                                            }
                                            isInvalid={!!errors.state}
                                        >
                                            <option value=''>Select</option>
                                            <option value='AL'>Alabama</option>
                                            <option value='AK'>Alaska</option>
                                            <option value='AZ'>Arizona</option>
                                            <option value='AR'>Arkansas</option>
                                            <option value='CA'>
                                                California
                                            </option>
                                            <option value='CO'>Colorado</option>
                                            <option value='CT'>
                                                Connecticut
                                            </option>
                                            <option value='DE'>Delaware</option>
                                            <option value='DC'>
                                                District Of Columbia
                                            </option>
                                            <option value='FL'>Florida</option>
                                            <option value='GA'>Georgia</option>
                                            <option value='HI'>Hawaii</option>
                                            <option value='ID'>Idaho</option>
                                            <option value='IL'>Illinois</option>
                                            <option value='IN'>Indiana</option>
                                            <option value='IA'>Iowa</option>
                                            <option value='KS'>Kansas</option>
                                            <option value='KY'>Kentucky</option>
                                            <option value='LA'>
                                                Louisiana
                                            </option>
                                            <option value='ME'>Maine</option>
                                            <option value='MD'>Maryland</option>
                                            <option value='MA'>
                                                Massachusetts
                                            </option>
                                            <option value='MI'>Michigan</option>
                                            <option value='MN'>
                                                Minnesota
                                            </option>
                                            <option value='MS'>
                                                Mississippi
                                            </option>
                                            <option value='MO'>Missouri</option>
                                            <option value='MT'>Montana</option>
                                            <option value='NE'>Nebraska</option>
                                            <option value='NV'>Nevada</option>
                                            <option value='NH'>
                                                New Hampshire
                                            </option>
                                            <option value='NJ'>
                                                New Jersey
                                            </option>
                                            <option value='NM'>
                                                New Mexico
                                            </option>
                                            <option value='NY'>New York</option>
                                            <option value='NC'>
                                                North Carolina
                                            </option>
                                            <option value='ND'>
                                                North Dakota
                                            </option>
                                            <option value='OH'>Ohio</option>
                                            <option value='OK'>Oklahoma</option>
                                            <option value='OR'>Oregon</option>
                                            <option value='PA'>
                                                Pennsylvania
                                            </option>
                                            <option value='RI'>
                                                Rhode Island
                                            </option>
                                            <option value='SC'>
                                                South Carolina
                                            </option>
                                            <option value='SD'>
                                                South Dakota
                                            </option>
                                            <option value='TN'>
                                                Tennessee
                                            </option>
                                            <option value='TX'>Texas</option>
                                            <option value='UT'>Utah</option>
                                            <option value='VT'>Vermont</option>
                                            <option value='VA'>Virginia</option>
                                            <option value='WA'>
                                                Washington
                                            </option>
                                            <option value='WV'>
                                                West Virginia
                                            </option>
                                            <option value='WI'>
                                                Wisconsin
                                            </option>
                                            <option value='WY'>Wyoming</option>
                                            <option value='AS'>
                                                American Samoa
                                            </option>
                                            <option value='GU'>Guam</option>
                                            <option value='MP'>
                                                Northern Mariana Islands
                                            </option>
                                            <option value='PR'>
                                                Puerto Rico
                                            </option>
                                            <option value='UM'>
                                                United States Minor Outlying
                                                Islands
                                            </option>
                                            <option value='VI'>
                                                Virgin Islands
                                            </option>
                                        </Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Required field
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={2} xs={4}>
                                    <Form.Group controlId='row9'>
                                        <Form.Label>Zip</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='zip'
                                            value={formValues.zip || ''}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleMainFormChange(e);
                                            }}
                                            isValid={touched.zip && !errors.zip}
                                            isInvalid={!!errors.zip}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.zip}
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '10px'}}>
                                <Col md={12}>
                                    <Form.Group as={Row}>
                                        <Col sm='6'>
                                        Will you be doing a direct pay from CC or Checking account?
                                        </Col>
                                        <Col sm='6'>
                                            <Form.Check
                                                inline
                                                label='Credit Card'
                                                type='radio'
                                                name='paymentMode'
                                                value='Credit Card'
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    handleMainFormChange(e);
                                                }}
                                                isInvalid={
                                                    (!formValues.paymentMode &&
                                                        touched.paymentMode) ||
                                                    !!errors.paymentMode
                                                }
                                            />
                                            <Form.Check
                                                inline
                                                label='Checking'
                                                type='radio'
                                                name='paymentMode'
                                                value='Checking'
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    handleMainFormChange(e);
                                                }}
                                                isInvalid={
                                                    (!formValues.paymentMode &&
                                                        touched.paymentMode) ||
                                                    !!errors.paymentMode
                                                }
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '10px'}}>
                                <Col md={12}>
                                    <Form.Group as={Row}>
                                        <Col sm='6'>
                                        What solution option would you like to sign up for?
                                        </Col>
                                        <Col sm='6'>
                                            <Form.Check
                                                inline
                                                label='Essential package'
                                                type='radio'
                                                name='solutionOption'
                                                value='Option 1 Essential package'
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    handleMainFormChange(e);
                                                }}
                                                isInvalid={
                                                    (!formValues.solutionOption &&
                                                        touched.solutionOption) ||
                                                    !!errors.solutionOption
                                                }
                                            />
                                            <Form.Check
                                                inline
                                                label='Secure package'
                                                type='radio'
                                                name='solutionOption'
                                                value='Option 2 Secure package'
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    handleMainFormChange(e);
                                                }}
                                                isInvalid={
                                                    (!formValues.solutionOption &&
                                                        touched.solutionOption) ||
                                                    !!errors.solutionOption
                                                }
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Label className='font-weight-bold mt-3' style={{marginTop: '25px'}}>
                                Site List
                            </Form.Label>
                            {rows.map((row, index) => (
                                <Fragment key={row.key}>
                                    <Row className='mt-3'>
                                        <Col sm={1}>
                                            <Form.Group>
                                                <Form.Label>Store#</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='storeNumber'
                                                    onChange={(e) =>
                                                        handleRowChange(
                                                            e,
                                                            row.key
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={5}>
                                            <Form.Group>
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='address'
                                                    value={row.address}
                                                    onChange={(e) =>
                                                        handleRowChange(
                                                            e,
                                                            row.key
                                                        )
                                                    }
                                                    isValid={
                                                        row.address.length > 0
                                                    }
                                                />
                                                <Form.Control.Feedback></Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Group>
                                                <Form.Label>City</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='city'
                                                    value={row.city}
                                                    onChange={(e) =>
                                                        handleRowChange(
                                                            e,
                                                            row.key
                                                        )
                                                    }
                                                    isValid={
                                                        row.city.length > 0
                                                    }
                                                />
                                                <Form.Control.Feedback></Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Group>
                                                <Form.Label>State</Form.Label>
                                                <Form.Control
                                                    as='select'
                                                    name='state'
                                                    onChange={(e) =>
                                                        handleRowChange(
                                                            e,
                                                            row.key
                                                        )
                                                    }
                                                    isValid={
                                                        row.state &&
                                                        row.state.length > 0
                                                    }
                                                >
                                                    <option value=''>
                                                        Select
                                                    </option>
                                                    <option value='AL'>
                                                        Alabama
                                                    </option>
                                                    <option value='AK'>
                                                        Alaska
                                                    </option>
                                                    <option value='AZ'>
                                                        Arizona
                                                    </option>
                                                    <option value='AR'>
                                                        Arkansas
                                                    </option>
                                                    <option value='CA'>
                                                        California
                                                    </option>
                                                    <option value='CO'>
                                                        Colorado
                                                    </option>
                                                    <option value='CT'>
                                                        Connecticut
                                                    </option>
                                                    <option value='DE'>
                                                        Delaware
                                                    </option>
                                                    <option value='DC'>
                                                        District Of Columbia
                                                    </option>
                                                    <option value='FL'>
                                                        Florida
                                                    </option>
                                                    <option value='GA'>
                                                        Georgia
                                                    </option>
                                                    <option value='HI'>
                                                        Hawaii
                                                    </option>
                                                    <option value='ID'>
                                                        Idaho
                                                    </option>
                                                    <option value='IL'>
                                                        Illinois
                                                    </option>
                                                    <option value='IN'>
                                                        Indiana
                                                    </option>
                                                    <option value='IA'>
                                                        Iowa
                                                    </option>
                                                    <option value='KS'>
                                                        Kansas
                                                    </option>
                                                    <option value='KY'>
                                                        Kentucky
                                                    </option>
                                                    <option value='LA'>
                                                        Louisiana
                                                    </option>
                                                    <option value='ME'>
                                                        Maine
                                                    </option>
                                                    <option value='MD'>
                                                        Maryland
                                                    </option>
                                                    <option value='MA'>
                                                        Massachusetts
                                                    </option>
                                                    <option value='MI'>
                                                        Michigan
                                                    </option>
                                                    <option value='MN'>
                                                        Minnesota
                                                    </option>
                                                    <option value='MS'>
                                                        Mississippi
                                                    </option>
                                                    <option value='MO'>
                                                        Missouri
                                                    </option>
                                                    <option value='MT'>
                                                        Montana
                                                    </option>
                                                    <option value='NE'>
                                                        Nebraska
                                                    </option>
                                                    <option value='NV'>
                                                        Nevada
                                                    </option>
                                                    <option value='NH'>
                                                        New Hampshire
                                                    </option>
                                                    <option value='NJ'>
                                                        New Jersey
                                                    </option>
                                                    <option value='NM'>
                                                        New Mexico
                                                    </option>
                                                    <option value='NY'>
                                                        New York
                                                    </option>
                                                    <option value='NC'>
                                                        North Carolina
                                                    </option>
                                                    <option value='ND'>
                                                        North Dakota
                                                    </option>
                                                    <option value='OH'>
                                                        Ohio
                                                    </option>
                                                    <option value='OK'>
                                                        Oklahoma
                                                    </option>
                                                    <option value='OR'>
                                                        Oregon
                                                    </option>
                                                    <option value='PA'>
                                                        Pennsylvania
                                                    </option>
                                                    <option value='RI'>
                                                        Rhode Island
                                                    </option>
                                                    <option value='SC'>
                                                        South Carolina
                                                    </option>
                                                    <option value='SD'>
                                                        South Dakota
                                                    </option>
                                                    <option value='TN'>
                                                        Tennessee
                                                    </option>
                                                    <option value='TX'>
                                                        Texas
                                                    </option>
                                                    <option value='UT'>
                                                        Utah
                                                    </option>
                                                    <option value='VT'>
                                                        Vermont
                                                    </option>
                                                    <option value='VA'>
                                                        Virginia
                                                    </option>
                                                    <option value='WA'>
                                                        Washington
                                                    </option>
                                                    <option value='WV'>
                                                        West Virginia
                                                    </option>
                                                    <option value='WI'>
                                                        Wisconsin
                                                    </option>
                                                    <option value='WY'>
                                                        Wyoming
                                                    </option>
                                                    <option value='AS'>
                                                        American Samoa
                                                    </option>
                                                    <option value='GU'>
                                                        Guam
                                                    </option>
                                                    <option value='MP'>
                                                        Northern Mariana Islands
                                                    </option>
                                                    <option value='PR'>
                                                        Puerto Rico
                                                    </option>
                                                    <option value='UM'>
                                                        United States Minor
                                                        Outlying Islands
                                                    </option>
                                                    <option value='VI'>
                                                        Virgin Islands
                                                    </option>
                                                </Form.Control>
                                                <Form.Control.Feedback></Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Group>
                                                <Form.Label>Zip</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='zip'
                                                    value={row.zip}
                                                    onChange={(e) =>
                                                        handleRowChange(
                                                            e,
                                                            row.key
                                                        )
                                                    }
                                                    isValid={
                                                        row.zip.length > 0 &&
                                                        row.zip.match(
                                                            /^\d{5}(?:[-\s]\d{4})?$/
                                                        )
                                                    }
                                                    isInvalid={
                                                        row.zip.length === 0
                                                            ? false
                                                            : !row.zip.match(
                                                                  /^\d{5}(?:[-\s]\d{4})?$/
                                                              )
                                                    }
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {row.zip.length > 0
                                                        ? 'Not a valid zip code'
                                                        : ''}
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback></Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {index === rows.length - 1 && index > 0 && (
                                        <Row
                                            className='justify-content-end'
                                            style={{
                                                width: '100%',
                                                margin: '0px'
                                            }}
                                        >
                                            <h6
                                                className='text-danger'
                                                onClick={() =>
                                                    handleRemoveSite(row.key)
                                                }
                                                style={{ cursor: 'pointer' }}
                                            >
                                                Remove
                                            </h6>
                                        </Row>
                                    )}
                                </Fragment>
                            ))}
                            <Row
                                className='justify-content-end mt-3'
                                style={{
                                    width: '100%',
                                    margin: '0px'
                                }}
                            >
                                <Button variant='primary' onClick={addSite}>
                                    Add Another Site
                                </Button>
                            </Row>
                            <Row
                                className='mt-5 justify-content-center'
                                style={{ width: '100%' }}
                            >
                                <Button
                                    variant='outline-dark'
                                    size='lg'
                                    type='submit'
                                    disabled={savingResponse}
                                >
                                    {savingResponse ? 'Loading' : 'Submit'}
                                </Button>
                            </Row>
                        </Form>
                    )}
                </Formik>
                <Row className='justify-content-center'>
                    {showToast.value && (
                        <div
                            className={`toast_div toast_color_${showToast.type}`}
                        >
                            <div className='toast_text'>
                                {showToast.message}
                            </div>
                        </div>
                    )}
                </Row>
            </Container>
        </div>
    );
};

export default FormComponent;
