import * as yup from 'yup';

export const formHeaders = [
    '#',
    'Business Legal Name',
    'Authorized Signer - Name',
    'Authorized Signer - Phone',
    'Authorized Signer - Email',
    'Billing Contact - Name',
    'Billing Contact - Phone',
    'Billing Contact - Email',
    'Billing Contact - Billing Address',
    'City',
    'State',
    'Zip',
    'Payment Mode',
    'Solution Option',
    'Secondary Contact Name',
    'Secondary Contact Email'
];

export const formSchema = yup.object().shape({
    businessLegalName: yup.string().required(),
    ausName: yup.string().required(),
    ausPhone: yup.string().required('Required field'),
    ausEmail: yup.string().required('Required field'),
    secName: yup.string().required('Required field'),
    secEmail: yup.string().required('Required field'),
    billName: yup.string().required(),
    billPhone: yup.string().required('Required field'),
    billEmail: yup.string().required('Required field'),
    billingAddress: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    zip: yup
        .string()
        .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Enter a valid zip code')
        .required('Required field'),
    paymentMode: yup.string().required(),
    solutionOption: yup.string().required()
});

export const formSchemaRows = yup.array().of(
    yup.object().shape({
        key: yup.number().required(),
        address: yup.string(),
        city: yup.string(),
        state: yup.string(),
        zip: yup
            .string()
            .matches(/^$|^\d{5}(?:[-\s]\d{4})?$/)
            .notRequired()
    })
);
